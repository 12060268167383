 <!--Navbar -->
 <nav class=" navbar navbar-expand-lg navbar-dark z-depth-0 upTop">

    <div class="collapse navbar-collapse" id="navbarSupportedContent-4">
        <ul class="navbar-nav ml-auto">
        
            <li class="nav-item">
                <a class="nav-link" href="https://www.facebook.com/villa.adam.Cyprus/">
                    <i class="fab fa-facebook-f"></i>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="https://www.instagram.com/golfvilla/">
                    <i class="fab fa-instagram"></i>
                </a>
            </li>
        </ul>
    </div>
</nav>
<!--/.Navbar -->