import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-big-slider',
  templateUrl: './big-slider.component.html',
  styleUrls: ['./big-slider.component.scss']
})
export class BigSliderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
