<link href="https://fonts.googleapis.com/css?family=Rubik&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css?family=Abril+Fatface&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css?family=Roboto:400,700|Open+Sans:400,700" rel="stylesheet">
<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css">
<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css">
<script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.4/jquery.min.js"></script>
<script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js"></script>

<!-- <div class="text-center">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div> -->

<div class="smooth">
    <section class="firstSection">
        <div class="row ">
            <div class="col-lg-12 col-md-12 col-xs-12">
                <app-big-slider></app-big-slider>

            </div>


        </div>
    </section>

    <section class="secondSection" id="secondSection">

        <div class="row ">
            <div class="centerTextbox">
                <h1 class="firstOverlay centerBig">"a Place <br> in the<br> sun"</h1>
            </div>

            <div class="col-lg-6 col-md-6 col-xs-12 ">
                <img class=" halfSectionPic inner-div" src="assets/images/5.jpg">

            </div>


            <div class="offset-lg-1 col-lg-4 offset-lg-1 col-md-6 col-xs-12  ">

                <h4>Villa Adam offers everything you could need for the perfect,
                    home away from home.</h4>
                <br>
                <p>

                    Within a tranquil setting, the large infinity pool overlooks the dreamy sea,
                    with a large terrace, ideal for socialising with friends, family or just
                    relaxing in the sunshine. As day turns to night time, you can enjoy sunsets
                    bursting with colours, followed by a sky full of gleaming stars as you dine
                    al fresco with the barbeque or relax in the luxurious lounge and dining area.
                    Set in a secure gated area, the seven-bedroom villa is designed, furnished
                    and equipped to the highest standards for a truly memorable and relaxing
                    holiday.
                    Please have a look around the image galleries of The Villa, the wonderful
                    places to visit in North Cyprus and feel free to contact us if you have
                    any questions, and our dedicated concierge will help however we can. </p>

            </div>


        </div>
    </section>

    <section class="thirdSection">
        <div class="row ">
            <div class="col-lg-12 col-md-12 col-xs-12 ">
                <div class="centerTextbox">
                    <h1 class="centerBig pdTop100 ">Book Your Trip Now!</h1><br><br><br>
                    <div class="offset-lg-5 col-lg-4 offset-lg-3 col-md-6 col-xs-12 ">
                        <a href="https://cyprusgolfvilla.guestybookings.com/" class="secondButton ">Check Dates </a>

                    </div>




                </div>
            </div><br><br>

            <!-- <div class=" offset-lg-2 col-lg-8 offset-lg-2 col-md-12 col-xs-12 ">
                <div class="row contactFormBox relative">
                    <div class="col-lg-3  ">
                        <img class="formImage " src="assets/images/formImage1.png">
                    </div>

                    <div class="col-lg-2 "><br>
                        <span class="inputsText">Full name:</span><br>
                        <input type="text" name="name" placeholder="Erez"><br> <br>

                        <span class="inputsText">Check-in:</span><br>
                        <input type="date" name="checkIn" value="Mickey"><br>


                    </div>
                    <div class="offset-lg-1 col-lg-2"> <br>
                        <span class="inputsText">Email:</span><br>
                        <input type="email" name="email"><br> <br>

                        <span class="inputsText">Check-out:</span><br>
                        <input type="date" name="checkOut" class="input-group date" placeholder="Pick The date"><br>

                    </div>
                    <div class="offset-lg-1 col-lg-3">
                        <input type="submit" value="Submit  >" class="buttonSubmit">
                    </div>
                </div>
            </div> -->


        </div>
    </section>

    <section class="fourSection">
        <div class="row ">
            <div class="view w-100">
                <img class=" w-100 scrollPic">
            </div>
        </div>
    </section>


    <section class="fiveSection" id="fiveSection">
        <div class="container">

            <div class="row">
                <div class="offset-lg-1 col-lg-11 col-md-12 col-xs-12 ">
                    <div class="centerTextbox">

                        <h3 class="leftMedium pdTop100">Fun, Sport & Nature</h3><br>
                        <h4 class="leftMedium2 thirdOverlay"> Discover the atractions</h4>
                    </div>
                </div><br><br>

                <mdb-slide>

                </mdb-slide>


            </div>

            <div class="row attractionsBox " *ngFor="let picture of pictures">
                <div class="offset-lg-1 col-lg-5">
                    <div>
                        <h4 class="pdTop50">{{picture.title}}</h4>
                        <br>
                        <p> {{picture.para}}</p>
                    </div>
                </div>

                <div class="col-lg-6"> <br>
                    <mdb-carousel [animation]="'fade'">
                        <mdb-carousel-item #beaches>
                            <div class="view w-100">
                                <img class="d-block w-100 bigPic" src="{{picture.imgSrc}}" alt="First slide">
                                <div class="waves-light"></div>
                            </div>

                            <div class="carousel-caption">
                                <h3>{{picture.title}}</h3>

                            </div>
                        </mdb-carousel-item>


                    </mdb-carousel>
                </div>

            </div>


        </div>

    </section>

    <section class="sixSection pdBottom80">
        <div class="container ">
            <div class="row ">
                <div class="col-lg-12 col-md-12 col-xs-12 ">
                    <h1 class="centerSmall pdTop80 pdBottom20">The Villa’s Facilities</h1>
                </div>


                <div class="offset-lg-2 col-lg-8 offset-lg-2 offset-md-2 col-md-8 offset-md-2  col-xs-12 pdBottom20">
                    <img class="companiesLogos pool " src="/assets/images/pool.png">
                    <img class="companiesLogos beach " src="/assets/images/beach.png">
                    <img class="companiesLogos wifi " src="/assets/images/wifi.png">
                </div>
                <div class=" offset-lg-2 col-lg-8 offset-lg-2 col-md-12 col-xs-12 ">
                    <img class="companiesLogos roomService " src="/assets/images/roomService.png">
                    <img class="companiesLogos kitchen " src="/assets/images/kitchen.png">
                    <img class="companiesLogos wifi " src="/assets/images/parking.png">
                </div>



            </div>
        </div>

    </section>

    <section class="sevenSection">
        <div class="container ">
            <div class="row ">
                <div class="col-lg-12 col-md-12 col-xs-12">
                    <h1 class="centerBig secondOverlay">And many more!</h1>
                    <h1 class="centerSmall fourOverlay">OUR GUESTS SAY...</h1>

                </div>


            </div>
        </div>

    </section>

    <section class="eightSection">
        <div class="row scrollPic2">
            <div class="view w-100 pdTop100">
                <div class="container col-lg-12 col-md-12 col-xs-12 ">
                    <div class="row ">
                        <!-- Section: Testimonials v.2 -->
                        <section class="text-center my-5 pdTop100 testamonialsBox">
                            <!-- Section heading -->
                            <!--Carousel Wrapper-->
                            <mdb-carousel [isControls]="true" [animation]="'slide'" class="testimonial-carousel">
                                <!--First slide-->
                                <mdb-slide>
                                    <div class="testimonial ">
                                        <!--Avatar-->
                                        <!-- <div class="avatar mx-auto mb-4">
                                            <img src="https://mdbootstrap.com/img/Photos/Avatars/img%20(30).jpg" class="rounded-circle img-fluid"
                                                alt="First sample avatar image">
                                        </div> -->
                                        <!--Content-->
                                        <p class="testamonialsText pdBottom20">
                                            <img src="assets/images/quote.png"><br> Villa Adam was even more incredible
                                            in person than
                                            it seemed online. The view from the pool overlooking the water was nothing
                                            short of magnificent.
                                            I play a lot of golf so it was great to be so close to the Korenium Golf
                                            Club
                                            and play a quick 9 holes before heading back over to the beach for the day.

                                        </p> <img src="assets/images/quoteRight.png" class="quote float-right ">
                                        <div class=" pdTop50">
                                            <h4 class="font-weight-bold testName"> David Acton </h4>
                                            <!--Review-->
                                            <i class="fas fa-star blue-text fa-2x"> </i>
                                            <i class="fas fa-star blue-text fa-2x"> </i>
                                            <i class="fas fa-star blue-text fa-2x"> </i>
                                            <i class="fas fa-star blue-text fa-2x"> </i>
                                            <i class="fas fa-star blue-text fa-2x"> </i>
                                        </div>
                                    </div>
                                </mdb-slide>

                                <mdb-slide>
                                    <div class="testimonial ">
                                        <!--Avatar-->
                                        <!-- <div class="avatar mx-auto mb-4">
                                                <img src="https://mdbootstrap.com/img/Photos/Avatars/img%20(30).jpg" class="rounded-circle img-fluid"
                                                    alt="First sample avatar image">
                                            </div> -->
                                        <!--Content-->
                                        <p class="testamonialsText pdBottom20">
                                            <img src="assets/images/quote.png"><br> The infinity pool was unbelievable.
                                            You see the pictures but in person it’s like almost breathtaking. The sun
                                            setting
                                            over the pool was definitely my favorite part of the villa.


                                        </p> <img src="assets/images/quoteRight.png" class="quote float-right ">
                                        <div class=" pdTop50">
                                            <h4 class="font-weight-bold testName pdTop80"> Mila Sokolov </h4>
                                            <!--Review-->
                                            <i class="fas fa-star blue-text fa-2x"> </i>
                                            <i class="fas fa-star blue-text fa-2x"> </i>
                                            <i class="fas fa-star blue-text fa-2x"> </i>
                                            <i class="fas fa-star blue-text fa-2x"> </i>
                                            <i class="fas fa-star blue-text fa-2x"> </i>
                                        </div>
                                    </div>
                                </mdb-slide>

                                <mdb-slide>
                                    <div class="testimonial ">
                                        <!--Avatar-->
                                        <!-- <div class="avatar mx-auto mb-4">
                                                    <img src="https://mdbootstrap.com/img/Photos/Avatars/img%20(30).jpg" class="rounded-circle img-fluid"
                                                        alt="First sample avatar image">
                                                </div> -->
                                        <!--Content-->
                                        <p class="testamonialsText pdBottom20">
                                            <img src="assets/images/quote.png"><br> I was blown away by the view over
                                            the pool overlooking the water. The sun setting there was absolutely
                                            unreal.


                                        </p> <img src="assets/images/quoteRight.png" class="quote float-right ">
                                        <div class=" pdTop50">
                                            <h4 class="font-weight-bold testName pdTop80">Anastas Alferov </h4>
                                            <!--Review-->
                                            <i class="fas fa-star blue-text fa-2x"> </i>
                                            <i class="fas fa-star blue-text fa-2x"> </i>
                                            <i class="fas fa-star blue-text fa-2x"> </i>
                                            <i class="fas fa-star blue-text fa-2x"> </i>
                                            <i class="fas fa-star blue-text fa-2x"> </i>
                                        </div>
                                    </div>
                                </mdb-slide>

                                <mdb-slide>
                                    <div class="testimonial ">
                                        <!--Avatar-->
                                        <!-- <div class="avatar mx-auto mb-4">
                                                        <img src="https://mdbootstrap.com/img/Photos/Avatars/img%20(30).jpg" class="rounded-circle img-fluid"
                                                            alt="First sample avatar image">
                                                    </div> -->
                                        <!--Content-->
                                        <p class="testamonialsText pdBottom20">
                                            <img src="assets/images/quote.png"><br> I would 100% stay here again.
                                            This place has everything I need and I feel like royalty waking up
                                            and walking out to that pool overlooking that view.


                                        </p> <img src="assets/images/quoteRight.png" class="quote float-right ">
                                        <div class=" pdTop50">
                                            <h4 class="font-weight-bold testName"> John Allerton </h4>
                                            <!--Review-->
                                            <i class="fas fa-star blue-text fa-2x"> </i>
                                            <i class="fas fa-star blue-text fa-2x"> </i>
                                            <i class="fas fa-star blue-text fa-2x"> </i>
                                            <i class="fas fa-star blue-text fa-2x"> </i>
                                            <i class="fas fa-star-half blue-text fa-2x"> </i>
                                        </div>
                                    </div>
                                </mdb-slide>

                            </mdb-carousel>
                            <!--/.Carousel Wrapper-->
                        </section>
                    </div>
                </div>

            </div>




        </div>

    </section>








</div>