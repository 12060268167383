import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
    public constructor(private title: Title) { }
    
    public ngOnInit(): void {
        this.title.setTitle("Villa Adam | Golf Villa in North Cyprus");

    }

  pictures: any[] = [
    {
        "title": "Beaches",

        "para": " North Cyprus is home to some of the most beautiful beaches"+
         "on the Mediterranean, with warm, clear seas and soft sand. Villa Adam"+
          "is located right on the beach. Within a short distance of the villa"+
           "you will also find Golden Beach, Korenium Beach and Acapulco Beach."+
            "Nearby, there is also Alagadi Beach, or Turtle Beach, which is renowned"+
             "worldwide as one of the best places to see Loggerhead and Green turtles"+
              "return every year to nest in the golden sands.",

        "imgSrc": "/assets/images/beaches.jpg"


    },
    {
        "title": "Golf",
        "para": "The top-quality golf courses set within stunning scenery attract" +
        "thousands of visitors to North Cyprus every year. Whether you are" +
        "an experienced pro or a complete novice, the area has something to" +
        "offer everyone. The fantastic Korenium Golf Club is within walking" +
        "distance of the villa, and our dedicated team can help you arrange" +
        "tee times and equipment rental to take any hassle out of your" +
        "golfing holiday and leave you free to enjoy the game.",

        "imgSrc": "/assets/images/golf.jpg"


    },

    {
        "title": "Diving",
        "para": "The warm and crystal clear waters surrounding North Cyprus" +
            "offer the perfect opportunity for divers from all over the world to" +
            "discover the fantastic and unspoilt marine life. The water temperature" +
            "in November is about 20’C, so the scuba diving season lasts from April" +
            "up until December.",

        "imgSrc": "/assets/images/diving.jpg"


    },

];

}
