
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { AgmCoreModule } from '@agm/core';
import { AppComponent } from './app.component';

import { MDBSpinningPreloader, MDBBootstrapModulesPro, ToastModule } from 'ng-uikit-pro-standard';
import { BigSliderComponent } from './components/big-slider/big-slider.component';
import { SmallSliderComponent } from './components/small-slider/small-slider.component';
import { Page404Component } from './components/page404/page404.component';
import { MenuComponent } from './components/menu/menu.component';

@NgModule({
    declarations: [
      AppComponent,
      BigSliderComponent,
      SmallSliderComponent,
      Page404Component,
      MenuComponent
    ],
    imports: [
      BrowserModule,
      BrowserAnimationsModule,
      FormsModule,
      HttpModule,
      ToastModule.forRoot(),
      MDBBootstrapModulesPro.forRoot(),
      AgmCoreModule.forRoot({
      //   https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en#key
        apiKey: 'ZDP3uvsj6fUXAv57b6kU',
      })
    ],
    providers: [MDBSpinningPreloader],
    bootstrap: [AppComponent],
    schemas:      [ NO_ERRORS_SCHEMA ]
  
  })
  export class AppModule {
     
  
  }