export const environment = {
    production: false,
    firebase: {
        apiKey: "AIzaSyDRhtw_17GCcSVdqk7HTIg0xIEBrLE5-mQ",
        authDomain: "villa-adam.firebaseapp.com",
        databaseURL: "https://villa-adam.firebaseio.com",
        projectId: "villa-adam",
        storageBucket: "",
        messagingSenderId: "915057271848",
        appId: "1:915057271848:web:570afedb55e0c868"
    }
};
