<link href="https://fonts.googleapis.com/css?family=Abril+Fatface&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css?family=Rubik&display=swap" rel="stylesheet">

<section class="firstSection">
    <mdb-carousel class="carousel slide carousel-fade" [animation]="'fade'">

        <mdb-carousel-item>

            <div class="view w-100">

                <img class="d-block w-100 bigPic" src="assets/images/1.jpg" alt="First slide">
                <div class="mask rgba-black-light waves-light" mdbWavesEffect></div>
            </div>
            <div class="carousel-caption">
                <app-menu></app-menu>
                <h1>Villa Adam</h1>
                <h3>YOUR NEXT VACATION STARTS HERE</h3><br><br>
                <a href="https://cyprusgolfvilla.guestybookings.com/" class="firstButton">Book Now </a>


            </div>
        </mdb-carousel-item>


        <mdb-carousel-item>
            <div class="view w-100">
                <img class="d-block w-100 bigPic" src="assets/images/2.jpg" alt="First slide">
                <div class="mask rgba-black-light waves-light" mdbWavesEffect></div>
            </div>
            <div class="carousel-caption">
                <app-menu></app-menu>
                <h1>Villa Adam</h1>
                <h3>YOUR NEXT VACATION STARTS HERE</h3><br><br>
                <a href="https://cyprusgolfvilla.guestybookings.com/" class="firstButton">Book Now </a>


            </div>
        </mdb-carousel-item>

        <mdb-carousel-item>
            <div class="view w-100">
                <img class="d-block w-100 bigPic" src="assets/images/3.jpg" alt="First slide">
                <div class="mask rgba-black-light waves-light" mdbWavesEffect></div>
            </div>
            <div class="carousel-caption">
                <app-menu></app-menu>
                <h1>Villa Adam</h1>
                <h3>YOUR NEXT VACATION STARTS HERE</h3><br><br>
                <a href="https://cyprusgolfvilla.guestybookings.com/" class="firstButton">Book Now </a>


            </div>
        </mdb-carousel-item>

        <mdb-carousel-item>
            <div class="view w-100">
                <img class="d-block w-100 bigPic" src="assets/images/4.jpg" alt="First slide">
                <div class="mask rgba-black-light waves-light" mdbWavesEffect></div>
            </div>
            <div class="carousel-caption">
                <app-menu></app-menu>

                <h1>Villa Adam</h1>
                <h3>YOUR NEXT VACATION STARTS HERE</h3><br><br>
                <a href="https://cyprusgolfvilla.guestybookings.com/" class="firstButton">Check Dates </a>


            </div>
        </mdb-carousel-item>

        <mdb-carousel-item>
            <div class="view w-100">
                <img class="d-block w-100 bigPic" src="assets/images/5.jpg" alt="First slide">
                <div class="mask rgba-black-light waves-light" mdbWavesEffect></div>
            </div>
            <div class="carousel-caption">
                <app-menu></app-menu>

                <h1>Villa Adam</h1>
                <h3>YOUR NEXT VACATION STARTS HERE</h3><br><br>
                <a href="https://cyprusgolfvilla.guestybookings.com/" class="firstButton">Check Dates </a>


            </div>
        </mdb-carousel-item>

        <mdb-carousel-item>
            <div class="view w-100">
                <img class="d-block w-100 bigPic" src="assets/images/6.jpg" alt="First slide">
                <div class="mask rgba-black-light waves-light" mdbWavesEffect></div>
            </div>
            <div class="carousel-caption">
                <app-menu></app-menu>

                <h1>Villa Adam</h1>
                <h3>YOUR NEXT VACATION STARTS HERE</h3><br><br>
                <a href="https://cyprusgolfvilla.guestybookings.com/" class="firstButton">Check Dates </a>


            </div>
        </mdb-carousel-item>

        <mdb-carousel-item>
            <div class="view w-100">
                <img class="d-block w-100 bigPic" src="assets/images/7.jpg" alt="First slide">
                <div class="mask rgba-black-light waves-light" mdbWavesEffect></div>
            </div>
            <div class="carousel-caption">
                <app-menu></app-menu>

                <h1>Villa Adam</h1>
                <h3>YOUR NEXT VACATION STARTS HERE</h3><br><br>
                <a href="https://cyprusgolfvilla.guestybookings.com/" class="firstButton">Order Now </a>


            </div>
        </mdb-carousel-item>





    </mdb-carousel>